import ReactOnRails from "react-on-rails";
import ExitIntentPopup from "../src/components/ExitIntentPopup/ExitIntentPopup";
import ArticleShow from "../src/pages/Articles/Show/ArticleShow";
import Ad from "../src/components/Ad/Ad";
import BrowserValidator from "../src/components/BrowserValidator/BrowserValidator";

ReactOnRails.register({
  ExitIntentPopup,
  ArticleShow,
  Ad,
  BrowserValidator
});
