import React, { useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";
import { useStyles } from "./ArticlesCarousel.style";
import CustomSlide from "./CustomSlide/CustomSlide";
import labels from "../../../config/labels";
import ChevronLeft from "../../Icons/ChevronLeft";
import ChevronRight from "../../Icons/ChevronRight";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import values from "../../Theme/themes/breakpoints/values";

SwiperCore.use([Pagination, Navigation, Autoplay]);

const DELAY_MILISECONDS = 7000;

const ArticlesCarousel = ({ items, darkBackground }) => {
  const classes = useStyles();
  const isMobile = screenWidthIsLowerThan(values.sm);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const getPagination = () => {
    if (items.length > 1) {
      return { clickable: true };
    }
    return false;
  };

  const getAutoplay = () => {
    if (items.length > 1 && !items[0].isVideo) {
      return { delay: DELAY_MILISECONDS };
    }
    return false;
  };

  const printPhotoCreditsWithLabel = (credits) => {
    if (credits) {
      return `${labels.PHOTO_CREDIT} ${credits}`;
    }
    return "";
  };
  return (
    <>
      <Swiper
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        className={classes.swiper}
        pagination={isMobile ? getPagination() : false}
        autoplay={getAutoplay()}
        loop={items.length > 1}
      >
        {items &&
          items.map((item, i) => (
            <SwiperSlide key={`swiper-slide-${i}`}>
              <CustomSlide
                key={i}
                contentUrl={item.contentUrl}
                title={item.title}
                caption={item.caption}
                credits={printPhotoCreditsWithLabel(item.credits)}
                alt_text={item.alt_text}
                isVideo={item.isVideo}
                documentUrl={item?.documentUrl}
                showArrows={items?.length > 1}
                darkBackground={darkBackground}
                width={item?.width}
                height={item?.height}
                oneImageOnly={items.length === 1}
                totalImages={items.length}
              />
            </SwiperSlide>
          ))}

        {items.length > 1 && !isMobile && (
          <>
            <div
              ref={prevRef}
              style={{ color: darkBackground ? "white" : "black" }}
              className={clsx(classes.navigator_button, classes.previous)}
            >
              <ChevronLeft fillColor={darkBackground ? "white" : "black"} />
              Previous
            </div>

            <div
              ref={nextRef}
              style={{ color: darkBackground ? "white" : "black" }}
              className={clsx(classes.navigator_button, classes.next)}
            >
              Next
              <ChevronRight fillColor={darkBackground ? "white" : "black"} />
            </div>
          </>
        )}
      </Swiper>
      <div className={classes.print_picture}>
        <img src={items[0].contentUrl} alt={items[0].alt_text} />
      </div>
    </>
  );
};

ArticlesCarousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      contentUrl: PropTypes.string.isRequired,
      caption: PropTypes.string,
      credits: PropTypes.string,
      alt_text: PropTypes.string,
      title: PropTypes.string,
      isVideo: PropTypes.bool,
    }).isRequired
  ).isRequired,
  darkBackground: PropTypes.bool,
};

export default ArticlesCarousel;
