import React, { useEffect } from "react";
import RightBlocks from "../../../../RightBlocks/RightBlocks";
import {
  useTown,
  withTownContextProvider,
} from "../../../../../context/TownContext";
import { bool, object } from "prop-types";

const Sidebar = ({ adTargets, townInfo, noAds }) => {
  const { loadBlocksBySideAndTown } = useTown();

  useEffect(() => {
    loadBlocksBySideAndTown(townInfo, "right");
  }, [townInfo]);

  return (
    <RightBlocks
      adTargets={adTargets}
      town={townInfo}
      section={null}
      noAds={noAds}
    />
  );
};

Sidebar.propTypes = {
  adTargets: object,
  townInfo: object,
  noAds: bool,
};

export default withTownContextProvider(Sidebar);
