import { makeStyles } from "../../Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { values, up: above, down: below },
  } = theme;
  const carouselImageHeightMobile = "300px";
  const carouselImageHeightDesktop = "500px";
  return {
    print_picture: {
      display: "none",
      ["& img"]: {
        width: "auto",
        height: theme.spacing(62.5),
      },
      "@media print": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: theme.palette.light.grey,
      },
    },
    swiper: {
      width: "100%",
      maxWidth: "100% !important",
      "& .swiper-button-next": {
        display: "block !important",
      },
      [above(500)]: {
        maxWidth: "100% !important",
      },
      "& .swiper-pagination": {
        [below(values.md)]: {
          top: `calc(${carouselImageHeightMobile} + ${spacing(1)}px)`,
        },
      },
      "& .swiper-slide": {
        width: "100% !important",
      }
    },

    navigator_button: {
      position: "absolute",
      cursor: "pointer",
      top: `calc(${carouselImageHeightDesktop} + ${spacing(2)}px)`,
      zIndex: 2,
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: theme.fontFamily.primary,
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      [below(values.md)]: {
        top: `calc(${carouselImageHeightMobile} + ${spacing(1)}px)`,
      },
      "& svg": {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
      [`@media print`]: {
        display: "none",
      },
    },
    higher_position: {
      bottom: "13%",
    },
    previous: {
      left: 0,
    },
    next: {
      right: 0,
    },
  };
});

export { useStyles };
